//
// ProVote
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { application } from "./application"

import BenutzerPasswordController from "./benutzer_password_controller"
application.register( 'benutzer-password', BenutzerPasswordController )

import LinkController from "./link_controller"
application.register( 'link', LinkController )

import ModalController from "./modal_controller"
application.register( 'modal', ModalController )

import NotificationController from "./notification_controller"
application.register( 'notification', NotificationController )

import TabController from "./tab_controller"
application.register( 'tab', TabController )