//
// ProVote
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"

export default class extends Controller
{
  connect()
  {
    this.element.querySelectorAll( '[data-link]' ).forEach( ( elt ) =>
    {
      elt.style.cursor = 'pointer';
      elt.addEventListener( 'click', this.select );
    });
  }

  // Link selected
  select( e )
  {
    let elt = e.target;

    while( !( 'link' in elt.dataset ))
    {
      if( elt.nodeName == "A" ) return;
      if( elt.nodeName == "BUTTON" ) return;
      elt = elt.parentElement;
      if( elt == null ) return;
    }

    let url = elt.dataset.link;

    if( 'target' in elt.dataset )
    {
      window.open( url, elt.dataset.target );
    }
    else
    {
      window.location = url;
    }
  }
}